@use 'sass:math';
@import '../../../photon/theme/global';

.section {
  @include content-container;

  &-bg-faded-gray {
    @include background-text-color($color-faded-gray);
  }

  &-bg-white {
    @include background-text-color($color-white);
  }

  &-large {
    padding-right: 0;
    padding-left: 0;
  }

  @include padding-classes;

  @media (min-width: $mq-medium-and-above) {
    &-small > * {
      width: math.div(2, 3) * 100%;
      margin-right: auto;
      margin-left: auto;
    }

    &-medium {
      width: 100%;
    }
  }
}

.text-intro {
  margin-bottom: $spacing-m;

  @media (min-width: $mq-medium-and-above) {
    width: math.div(2, 3) * 100%;
    margin: 0 auto $spacing-l;
  }
}

.calculator-box {
  width: calc(#{math.div(2, 3) * 100%} - #{2 * $spacing-m});
  margin: -$spacing-l auto 0;

  @media (max-width: $mq-below-medium) {
    width: calc(100% - #{2 * $spacing-m});
  }

  @media (max-width: $mq-below-small) {
    width: 100%;
    margin: -$spacing-l 0 0;
  }
}
