@import '../../theme/animations';

.logo {
  [class*='logo-sun'] {
    transition: transform $small-intro;

    *:focus-visible > &,
    *:hover > & {
      transform: translate(0.25rem, -0.125rem);
    }

    *:active > & {
      transform: translate(0.625rem, -0.3125rem);
    }
  }
}
