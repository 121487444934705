@import '../../../../../packages/photon/theme/global';

.root {
  @include padding-classes;

  &.theme-white {
    background-color: $color-white;
  }

  &.theme-faded-gray {
    background-color: $color-faded-gray;
  }
}

.funnel-form {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-block: $spacing-xs;
  padding-inline: $spacing-s;
  gap: $spacing-s;
}

.button-wrapper {
  flex-grow: 1;

  @media (min-width: $mq-medium-and-above) {
    & > :first-child {
      flex-grow: 0;
    }
  }
}

.controls {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: $spacing-xxs;

  & > :first-child {
    flex-grow: 1;
  }

  @media (min-width: $mq-small-and-above) {
    gap: $spacing-s;
  }
}

.text-field {
  /* Box CTA: 48px, Textfield: 43px */
  margin-top: calc((48 - 43) / 2) + px;
}

.stage-card {
  display: flex;
  flex-direction: column;
  /* stylelint-disable-next-line meowtec/no-px */
  max-width: 800px;
  margin: 0 auto;
  padding-block: $spacing-xxs;
  list-style-type: none;
  border-radius: $border-radius-s;

  @media (min-width: $mq-medium-and-above) {
    flex-direction: row;
  }

  &.theme-white {
    background-color: $color-faded-gray;
  }

  &.theme-faded-gray {
    background-color: $color-white;
  }
}

.styled-search-icon {
  margin-right: $spacing-xs;
}
