@use 'sass:math';
@import '../../../photon/theme/global';

.container {
  padding-top: $spacing-l;
  padding-bottom: $spacing-l;

  @include content-container;

  @media (min-width: $mq-small-and-above) {
    display: grid;
    grid-template-areas: 'text-intro text-intro' 'facts-top-left facts-right' 'facts-bottom-left facts-right';
    grid-template-columns: 1fr 1fr;
    padding-top: $spacing-xl;
    padding-bottom: $spacing-xxs;
  }

  &-bg-faded-gray {
    @include background-text-color($color-faded-gray);
  }

  &-bg-white {
    @include background-text-color($color-white);
  }
}

.text-intro {
  grid-area: text-intro;
  margin-bottom: $spacing-m;

  @media (min-width: $mq-medium-and-above) {
    width: math.div(2, 3) * 100%;
    margin: 0 auto $spacing-l;
  }
}

.fact-item {
  padding-bottom: $spacing-s;

  @media (min-width: $mq-small-and-above) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: math.div(1, 12) * 100%;
  }

  &-1 {
    grid-area: facts-top-left;

    @media (min-width: $mq-small-and-above) {
      padding-right: math.div(0.5, 12) * 100%;
    }

    @media (min-width: $mq-medium-and-above) {
      padding-right: math.div(1, 12) * 100%;
      padding-left: math.div(2, 12) * 100%;
    }
  }

  &-2 {
    grid-area: facts-right;

    @media (min-width: $mq-small-and-above) {
      padding-left: math.div(0.5, 12) * 100%;
    }

    @media (min-width: $mq-medium-and-above) {
      padding-right: math.div(2, 12) * 100%;
      padding-left: math.div(1, 12) * 100%;
    }
  }

  &-3 {
    grid-area: facts-bottom-left;

    @media (min-width: $mq-small-and-above) {
      padding-right: math.div(0.5, 12) * 100%;
    }

    @media (min-width: $mq-medium-and-above) {
      padding-right: math.div(1, 12) * 100%;
      padding-left: math.div(4, 12) * 100%;
    }
  }
}
