@import '../../theme/colors';
@import '../../theme/mediaqueries';

@keyframes sun-animation {
  0% {
    transform: translate3d(0, 0, 0) rotate(0.1deg);
  }

  50% {
    transform: translate3d(10%, -5%, 0) rotate(0.1deg);
  }

  100% {
    transform: translate3d(0, 0, 0) rotate(0.1deg);
  }
}

.svg {
  width: 2.25rem;

  @media (min-width: $mq-small-and-above) {
    width: 6.25rem;
  }
}

.sun {
  animation-name: sun-animation;
  animation-duration: 2s;
  animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  animation-iteration-count: infinite;
  fill: $color-orange;
}

.ring {
  fill: $color-black;

  &.white {
    fill: $color-white;
  }
}
