@import '../../theme/mediaqueries';
@import '../../theme/font-weight';

.accent-text {
  font-weight: $font-weight-bold;
  line-height: 140%;
}

.size-m {
  font-size: 1.125rem;

  @media (min-width: $mq-desktop) {
    font-size: 1.25rem;
  }
}

.size-l {
  font-size: 1.5625rem;

  @media (min-width: $mq-desktop) {
    font-size: 1.75rem;
  }
}
