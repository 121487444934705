@import '../../../photon/theme/global';

.figcaption {
  max-width: #{$breakpoint-large}px;
  margin: 0 auto;
  padding: $spacing-xxs 0 0;

  &-large {
    padding: $spacing-xxs $spacing-xs;

    @media (min-width: $mq-medium-and-above) {
      padding: $spacing-xxs $spacing-xl;
    }
  }
}
