@import '../../../photon/theme/global';

.item {
  display: flex;
  align-items: flex-start;
  margin-bottom: $spacing-xs;
  list-style: none;
}

.icon {
  // aligning icons with text – we all love some magic numbers
  margin-top: 0.3em;
  margin-right: $spacing-xs;
}

.link {
  width: 100%;
}
