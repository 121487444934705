@import '../../../photon/theme/global';

.section {
  display: grid;
  grid-template-areas: 'media' 'content';
  grid-template-columns: 1fr;

  @media (min-width: $mq-medium-and-above) {
    grid-template-areas: 'media content';
    grid-template-columns: 1fr 1fr;
    align-items: center;

    &.reverse {
      grid-template-areas: 'content media';
    }
  }

  @include content-container;
  @include padding-classes;

  @each $color-name, $color-value in $cms-colors {
    &-bg-#{'' + $color-name} {
      background-color: $color-value;

      @include get-text-color($color-value);
    }
  }

  @each $color-name, $color-value in $cms-colors {
    &-box-bg-#{'' + $color-name} {
      .media,
      .content {
        background-color: $color-value;

        @include get-text-color($color-value);
      }
    }
  }
}

.media {
  grid-area: media;
  padding-bottom: $spacing-xs;

  @media (min-width: $mq-medium-and-above) {
    padding-right: $spacing-s;
    padding-bottom: unset;

    .reverse & {
      padding-right: unset;
      padding-left: $spacing-s;
    }
  }

  .boxed & {
    padding: $spacing-xs;
    border-top-left-radius: $border-radius-s;
    border-top-right-radius: $border-radius-s;
  }

  @media (min-width: $mq-medium-and-above) {
    .boxed & {
      padding-top: $spacing-m;
      padding-bottom: $spacing-m;
    }

    .boxed:not(.reverse) & {
      padding-left: $spacing-s;
      border-top-left-radius: $border-radius-s;
      border-top-right-radius: unset;
      border-bottom-left-radius: $border-radius-s;
    }

    .boxed.reverse & {
      padding-right: $spacing-s;
      border-top-left-radius: unset;
      border-top-right-radius: $border-radius-s;
      border-bottom-right-radius: $border-radius-s;
    }
  }
}

.content {
  display: flex;
  grid-area: content;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  width: 100%;
  gap: $spacing-xxs;

  @media (min-width: $mq-medium-and-above) {
    padding-left: $spacing-s;

    .reverse & {
      padding-right: $spacing-s;
      padding-left: unset;
    }
  }

  .boxed & {
    padding: 0 $spacing-xs $spacing-xs;
    border-bottom-right-radius: $border-radius-s;
    border-bottom-left-radius: $border-radius-s;
  }

  @media (min-width: $mq-medium-and-above) {
    .boxed & {
      padding-top: $spacing-m;
      padding-bottom: $spacing-m;
    }

    .boxed:not(.reverse) & {
      padding-right: $spacing-s;
      border-top-right-radius: $border-radius-s;
      border-bottom-right-radius: $border-radius-s;
      border-bottom-left-radius: unset;
    }

    .boxed.reverse & {
      padding-left: $spacing-s;
      border-top-left-radius: $border-radius-s;
      border-bottom-right-radius: unset;
      border-bottom-left-radius: $border-radius-s;
    }
  }
}

.tagline {
  color: $color-gray;
}

.price {
  margin-top: $spacing-l;
}

.price-small {
  opacity: 0.6;
}
