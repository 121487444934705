@use 'sass:math';
@import '../../../photon/theme/global';

.container {
  @include content-container;

  @media (min-width: $mq-medium-and-above) {
    > * {
      margin-right: math.div(100%, 12) * 2;
      margin-left: math.div(100%, 12) * 2;
    }
  }

  &-bg-faded-gray {
    @include background-text-color($color-faded-gray);
  }

  &-bg-white {
    @include background-text-color($color-white);
  }

  @include padding-classes;
}

.text-intro {
  margin-bottom: $spacing-m;

  @media (min-width: $mq-medium-and-above) {
    margin-bottom: $spacing-l;
  }
}

.items {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: math.div(100%, 12) * 10;
  margin-right: auto;
  margin-left: auto;

  > * {
    width: 50%;

    @media (min-width: $mq-small-and-above) {
      width: 25%;
    }
  }

  &.three-cols {
    @media (min-width: $mq-small-and-above) {
      width: math.div(100%, 12) * 8;

      > * {
        width: math.div(1, 3) * 100%;
      }
    }
  }

  &.center {
    align-items: center;
  }
}
