@import '../../../photon/theme/global';

.text-intro {
  &-centered-mobile {
    @media (max-width: $mq-below-small) {
      text-align: center;
    }
  }

  &-centered-desktop {
    @media (min-width: $mq-small-and-above) {
      text-align: center;
    }
  }
}

.headline {
  &:not(:only-child) {
    margin-bottom: $spacing-xxs;
  }
}

.chip {
  margin-bottom: $spacing-xxs;

  @media (min-width: $mq-small-and-above) {
    margin-bottom: $spacing-xs;
  }
}

.text-o-stage-split {
  @media (min-width: $mq-medium-and-above) {
    max-width: calc((100% / 12) * 11 - $spacing-m);
  }
}

.rich-text-headline {
  @media (min-width: $mq-medium-and-above) {
    max-width: calc((100% / 12) * 11 - $spacing-m);
  }

  &:not(:last-child) {
    margin-bottom: $spacing-xxs;
  }
}

.button {
  margin-top: $spacing-xs;
}
