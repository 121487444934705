@import '../../../photon/theme/global';

.container-maintenance {
  padding: $spacing-xxl 0;
  background: $color-faded-gray;

  @media (min-width: $mq-medium-and-above) {
    padding: $spacing-3xl 0;
  }

  @include content-container;
}

.container-form {
  padding: $spacing-m $spacing-xs;
  background: $color-faded-gray;
  scroll-margin-top: 8rem;

  @media (min-width: $mq-medium-and-above) {
    padding-top: $spacing-xl;
    padding-bottom: $spacing-xl;
  }

  &-bg-white {
    @include background-text-color($color-white);
  }

  &-bg-faded-gray {
    @include background-text-color($color-faded-gray);
  }

  @media (min-width: $mq-medium-and-above) {
    padding-right: max($spacing-xs, calc((100% - #{$breakpoint-small}px) / 2));
    padding-left: max($spacing-xs, calc((100% - #{$breakpoint-small}px) / 2));
  }
}

.text-intro:not(:only-child) {
  margin-bottom: $spacing-m;

  @media (min-width: $mq-small-and-above) {
    margin-bottom: $spacing-l;
  }
}

.loader {
  display: grid;
  margin: auto;
}
