@import '../../theme/mixins/focus-ring';
@import '../../theme/spacing';
@import '../../theme/opacities';
@import '../../theme/mediaqueries';
@import '../../theme/brand-colors';
@import './mixins';

.cta {
  display: inline-flex;
  flex-grow: 0;
  align-items: center;
  justify-content: center;
  width: auto;
  transition: 0.1s;
  border: none;
  border-radius: 3rem;
  outline: none;
  font: inherit;
  font-weight: 600;
  line-height: 1.7;
  text-align: center;
  cursor: pointer;
  appearance: none;

  @include focus-ring;

  &:active {
    transform: scale(0.98);
    outline: none;
  }

  &:disabled,
  &[disabled] {
    pointer-events: none;

    &.light {
      background-color: color-mix(in srgb, $color-black 11%, transparent);
      color: color-mix(in srgb, $color-black 33%, transparent);

      &.outline {
        border: 1px solid color-mix(in srgb, $color-white 33%, transparent);
        background: color-mix(in srgb, $color-white 23%, transparent);
        color: color-mix(in srgb, $color-white 33%, transparent);
      }
    }

    &.dark {
      background-color: color-mix(in srgb, $color-white 23%, transparent);
      color: color-mix(in srgb, $color-white 53%, transparent);

      &.outline {
        border: 1px solid color-mix(in srgb, $color-white 53%, transparent);
        background: none;
        color: color-mix(in srgb, $color-white 53%, transparent);
      }
    }
  }

  &.primary {
    background-color: $color-orange-300;
    color: $color-black;

    &:hover {
      background-color: color-mix(in srgb, currentcolor 11%, $color-orange-300);
    }
  }

  &.destructive {
    background-color: $color-red-600;
    color: $color-white;

    &:hover {
      background-color: color-mix(in srgb, currentcolor 11%, $color-red-600);
    }
  }

  &.neutral {
    &.light {
      &.filled {
        background: $color-gray-700;
        color: $color-white;

        &:hover {
          background: color-mix(in srgb, $color-white 11%, $color-gray-700);
        }
      }

      &.tonal {
        background: color-mix(in srgb, $color-black 11%, transparent);
        color: $color-gray-700;

        &:hover {
          background: color-mix(in srgb, $color-black 11%, $color-black 11%);
        }
      }

      &.outline {
        border: 1px solid color-mix(in srgb, $color-black 22%, transparent);
        background: none;
        color: $color-gray-700;

        &:hover {
          background: color-mix(in srgb, $color-black 11%, transparent);
        }
      }
    }

    &.dark {
      &.filled {
        background: $color-white;
        color: $color-gray-700;

        &:hover {
          background: color-mix(in srgb, $color-black 11%, $color-white);
        }
      }

      &.tonal {
        background: color-mix(in srgb, $color-white 23%, transparent);
        color: $color-white;

        &:hover {
          background: color-mix(in srgb, $color-white 23%, $color-white 23%);
        }
      }

      &.outline {
        border: 1px solid color-mix(in srgb, $color-white 38%, transparent);
        background: none;
        color: $color-white;

        &:hover {
          background: color-mix(in srgb, $color-white 11%, transparent);
        }
      }
    }
  }
}

.large {
  @include box-cta-large;
}

.medium {
  @include box-cta-medium;
}

.small {
  @include box-cta-small;
}

.wide {
  width: 100%;
}

@keyframes loading {
  0% {
    content: '';
  }

  25% {
    content: '.';
  }

  50% {
    content: '..';
  }

  75% {
    content: '...';
  }
}

.text {
  display: inline-block;

  .icon + & {
    margin-left: $spacing-xxs;
  }

  .loading &::after {
    content: '';
    display: inline-block;
    width: 1rem;
    animation: loading 1.5s linear infinite;
    text-align: left;
  }
}

.icon {
  display: inline-flex;

  svg {
    display: block;
  }
}
