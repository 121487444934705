@use 'sass:math';
@import '../../../photon/theme/global';

.footnotes {
  padding: $spacing-xs;

  @media (min-width: $mq-medium-and-above) {
    padding: $spacing-s calc((100% - #{$breakpoint-large}px) / 2);

    > * {
      margin-right: $spacing-xl;
      margin-left: $spacing-xl;
    }
  }

  &.light {
    background-color: $color-background-gray;
  }

  &.dark {
    background-color: $color-dark-gray;
  }
}
